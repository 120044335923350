<template>
    <v-autocomplete
        :items="clients"
        :item-text="getItemText"
        :loading="fetching"
        :search-input.sync="search"
        hide-details
        flat
        :value="client"
        return-object
        :clearable="false"
        label="Client Select"
        @input="setClient"
    >
    </v-autocomplete>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
    name: 'ClientPicker',
    model: {
        prop: 'client',
        event: 'input',
    },
    props: {
        client: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            search: null,
        }
    },
    computed: {
        ...mapGetters({
            searchClients: 'client/getItems',
            fetching: 'client/getFetching',
        }),
        clients() {
            // make sure that the client exists in the search client array.
            const clientInArray = this.searchClients.find((c) => c.id === this.client?.id)

            // if the client isn't in the search Array, append the client to the list.
            return clientInArray ? this.searchClients : [this.client, ...this.searchClients]
        },
    },
    watch: {
        search: async function (name) {
            clearTimeout(this.searchDebounce)
            this.searchDebounce = setTimeout(async () => {
                if (!name) return

                // avoid making a request after the client is selected
                if (this.searchClients.some((c) => c.name === name)) {
                    return
                }

                await this.cget({ params: { size: 10, search: { name } } })
            }, 500)
        },
    },
    methods: {
        ...mapActions('client', ['cget']),
        setClient(client) {
            const { id } = client
            const { name, params } = this.$route
            if (id !== params.id) {
                this.$router.push({ name, params: { id } })
            }
            this.$emit('input', client)
        },
        getItemText(item) {
            //Show the name, state, and the last 12 of the id to help with duplicate names
            return `${item.name} ${item.state} (${item.id.slice(-12)})`
        },
    },
}
</script>
