<template>
    <child-layout title="Authentications">
        <client-picker v-if="illuminateEmployee" />
        <template v-if="client && !clientFetching && !clientErrs.length">
            <v-row class="ma-1">
                <v-col>
                    <h4>
                        <router-link :to="{ name: 'client-view', params: { id: client.id } }">
                            {{ client.displayName }}
                        </router-link>
                        - {{ location }}
                    </h4>
                </v-col>
                <v-col class="text-right">
                    <create-config-button :client-id="clientId"></create-config-button>
                </v-col>
            </v-row>
            <ag-grid-simple
                :key="client.id"
                :row-data="auths"
                :column-defs="columnDefs"
                :fetching="authFetching"
            >
            </ag-grid-simple>
        </template>
    </child-layout>
</template>
<script>
import ChildLayout from '@/components/layout/ChildLayout'
import ColumnDef from '@/helpers/ag-grid/columnDef/imt/authentications'
import ClientPicker from '@/components/ClientPicker'
import { mapActions, mapGetters } from 'vuex'
import AgGridSimple from '@/components/table/AgGridSimple'
import CreateConfigButton from './CreateConfigButton'
import roles from '@/helpers/security/roles'

export default {
    name: 'IMTAuthentications',
    components: {
        ChildLayout,
        ClientPicker,
        AgGridSimple,
        CreateConfigButton,
    },
    data: () => ({
        columnDefs: ColumnDef,
        client: null,
    }),
    computed: {
        ...mapGetters({
            clientFetching: 'client/getFetching',
            clientErrs: 'client/getErrors',
            getClientById: 'client/getItemById',
            auths: 'authentication/getItems',
            authFetching: 'authentication/getFetching',
            districtClientId: 'getIlluminateClientId',
        }),
        ...mapGetters('auth', ['hasRole', 'getUser']),
        clientId: function () {
            return this.illuminateEmployee ? this.$route.params.id : this.districtClientId
        },
        location: function () {
            if (this.client.county) {
                return `${this.client.state},${this.client.county}`
            }
            return this.client.state
        },
        illuminateEmployee: function () {
            return this.hasRole([roles.ROLE_ILLUMINATE_EMPLOYEE])
        },
        canCreate: function () {
            return this.hasRole([
                roles.ROLE_ILLUMINATE_EMPLOYEE,
                roles.ROLE_IMT_AUTHENTICATIONS_CREATE,
            ])
        },
    },
    watch: {
        clientId: async function (clientId) {
            await this.fetchData(clientId)
        },
    },
    mounted: async function () {
        await this.fetchData(this.clientId)
    },
    methods: {
        ...mapActions({
            fetchAuths: 'authentication/cget',
            fetchClient: 'client/get',
            clearErrors: 'client/clearErrors',
        }),
        async fetchData(clientId) {
            if (!clientId) return

            if (this.clientErrs.length) {
                this.clearErrors()
            }

            await Promise.all([this.fetchClient(clientId), this.fetchAuths({ clientId })])
            this.client = this.getClientById(clientId)
        },
    },
}
</script>
